import React from 'react';

import buildingsVloerenLogo from '../assets/img/buildingsvloeren.png'
import buildingsLogo from '../assets/img/buildings-icon.png'
import pearlGreyLogo from '../assets/img/pearl-grey-by-eric-kuster.png'
import shadesLogo from '../assets/img/shades-by-eric-kuster.png'
import pietBoonLogo from '../assets/img/piet-boon-paint-and-plaster.png'

import './Content.scss'

const Content = () => {
    return (
        <div className="Content">
            <div className="Block mobile-only Intro">
                <img src={buildingsLogo} alt="Buildings Logo" />
                <p>THE BUILDINGS GROUP BRINGS TOGETHER EXCEPTIONAL BRANDS WITH ONE GOAL. "CREATING THE ULTIMATE LIVING EXPERIENCE"
                    IT DOES THIS BY OPENING UP EXISTING MARKETS WITH NEW VALUE PROPOSITIONS FOR ALL ITS BRANDS EVERYTHING REVOLVES AROUND
                    ORIGIN SINCERITY SEXYNESS AND DEDICATION TO QUALITY.</p>
            </div>
            <a href="https://erickusterflooring.com" target="_blank" rel="noreferrer" className="Block">
                <img src={pearlGreyLogo} alt="PEARL GREY by Eric Kuster" />
            </a>
            <a href="https://shadesbyerickuster.com" target="_blank" rel="noreferrer" className="Block">
                <img src={shadesLogo} alt="SHADES by Eric Kuster" />
            </a>
            <a href="https://buildingsvloeren.nl" target="_blank" rel="noreferrer" className="Block">
                <img src={buildingsVloerenLogo} alt="Buildings Vloeren" />
            </a>
            <a href="https://pietboonpaintandplaster.com" target="_blank" rel="noreferrer" className="Block">
                <img src={pietBoonLogo} alt="Piet Boon Paint & Plaster" />
            </a>
            <div className="Background desktop-only">
                <img src={buildingsLogo} alt="Buildings Logo" />
                <p>THE BUILDINGS GROUP BRINGS TOGETHER EXCEPTIONAL BRANDS WITH ONE GOAL. "CREATING THE ULTIMATE LIVING EXPERIENCE"
                    IT DOES THIS BY OPENING UP EXISTING MARKETS WITH NEW VALUE PROPOSITIONS FOR ALL ITS BRANDS EVERYTHING REVOLVES AROUND
                    ORIGIN SINCERITY SEXYNESS AND DEDICATION TO QUALITY.</p>
            </div>
        </div>
    );
};

export default Content;