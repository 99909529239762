import React from 'react';
import { Link } from 'react-router-dom';

import './Header.scss'

const Header = () => {
    return (
        <div className="Header">
            <div className="Header_nav">
                <Link to="/home">Home</Link>
                <Link to="/collaborate">Collaborate</Link>
                <a href="mailto:kevin@buildingsgroup.nl">Contact</a>
            </div>
        </div>
    );
};

export default Header;