import React from 'react';

import './Collaborate.scss';
import buildingsLogo from '../assets/img/logo.svg'

const Collaborate = () => {
    return (
        <div className="Collaborate">
            <h2>Collaborate</h2>
            <p>BUILDINGS GROUP started with a passion, that turned into a vision.</p>
            <p>"TO CREATE THE ULTIMATE LIVING EXPERIENCE"</p>
            <p>So we started building concepts.</p>
            <p><strong>From idea, to product, to exceptional market value.</strong></p>
            <p>Do you have an extraordinary product, but no power to market it?</p>
            <p>Let's work together. Send your business proposition to: kevin@buildingsgroup.nl.</p>
            <p></p>
            <img src={buildingsLogo} alt="Buildings Vloeren" />

        </div>
    );
};

export default Collaborate;