import React from 'react';
import Content from './Content';
import Footer from './Footer';
import Header from './Header';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Collaborate from './Collaborate';

const MainComponent = () => {
    return (
        <Router>
            <Routes>
                <Route path="/collaborate" element={
                    <div className={`MainComponent MainComponent__collaborate`}>
                        <Header />
                        <Collaborate />
                        <Footer />
                    </div>} />
                <Route path="*" element={
                    <div className={`MainComponent`}>
                        <Header />
                        <Content />
                        <Footer />
                    </div>
                } />
            </Routes>
        </Router>
    );
};

export default MainComponent;